<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-show-modal-select-dining-option"
      data-bs-toggle="modal"
      data-bs-target="#modalSelectDiningOption"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-hide-modal-select-dining-option"
      data-bs-target="#modalSelectDiningOption"
      data-bs-dismiss="modal"
    ></button>
    <div
      class="modal fade"
      id="modalSelectDiningOption"
      tabindex="-1"
      aria-labelledby="modalSelectDiningOption"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">Dining Location</div>
            <div class="d-flex justify-content-between mt-3">
              <div class="text-center" @click="selectDiningOption(2)">
                <img
                  src="@/assets/dine_in.png"
                  alt=""
                  class="ms-2"
                  style="height: 100px; width: auto"
                />
                <div>Dine in</div>
              </div>
              <div class="text-center" @click="selectDiningOption(1)">
                <img
                  src="@/assets/take_away.jpg"
                  alt=""
                  class="ms-2"
                  style="height: 100px; width: auto"
                />
                <div>Takeaway</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalSelectDiningOption",
  props: {
    selectDiningOption: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: hidden !important;
}

.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
}

.modal-content {
  max-width: 350px;
}

.btn-close-modal {
  border: 1px solid #d3d3d3;
  padding: 5px 30px;
  text-align: center;
  border-radius: 10px;
  background: none;
}
</style>
