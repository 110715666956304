<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-show-modal-input-buzzer-number"
      data-bs-toggle="modal"
      data-bs-target="#modalInputBuzzerNumber"
    ></button>
    <div
      class="modal fade"
      id="modalInputBuzzerNumber"
      tabindex="-1"
      aria-labelledby="modalInputBuzzerNumberLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="phone-keypad mt-3">
              <div class="input-text">Input 2 Digit Buzzer No.</div>
              <div class="display mt-1">
                {{ input }}
              </div>
              <div class="keys">
                <div v-for="key in keys" :key="key">
                  <button
                    @click="handleKeyClick(key)"
                    class="key"
                    :class="{ 'key-delete': key === 'X' || key === 'C' }"
                  >
                    {{ key }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn-close-modal"
              data-bs-dismiss="modal"
              @click="inputBuzzerNumber(input)"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInputBuzzerNumber",
  props: {
    keyReload: {
      required: true,
    },
    inputBuzzerNumber: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      keys: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "C", "0", "X"],
      input: "",
    };
  },
  mounted() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
  },
  methods: {
    handleKeyClick(key) {
      if (key === "C") {
        this.input = "";
      } else if (key === "X") {
        this.input = this.input.slice(0, -1);
      } else {
        if (this.input.length < 2) {
          this.input += key;
        }
      }
    },
    watch: {
      keyReload() {
        this.input = '';
      }
    }
  },
};
</script>

<style scoped>
.modal {
  overflow-y: hidden !important;
}

.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
}

.modal-content {
  max-width: 300px;
}

.btn-close-modal {
  border: 1px solid #d3d3d3;
  padding: 5px 30px;
  text-align: center;
  border-radius: 10px;
  background: none;
}

/* Phoen keypad */
.phone-keypad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: auto;
}

.display {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font-size: 32px;
  padding: 5px 10px;
}

.keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.key {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.key:focus {
  background-color: #e0e0e0;
}
</style>
